/* eslint-disable @typescript-eslint/no-explicit-any */
import { Languages, LocalStorageKeys, Platform, SaleChannel } from '@/constants'
import type {
  InternalAxiosRequestConfig as AxiosConfig,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'

let context = <Record<string, string>>{}
interface AxiosMetadaInterface extends AxiosRequestConfig {
  metadata: any
}
type ContextOverride = { language?: string | null; channel?: string | null }
export const setContext = async (_context: any, override?: ContextOverride) => {
  context = _context
  if (override && override.language) {
    if (context.language) {
      override.language = context.language
    } else {
      context = {
        ...context,
        ...{ language: override.language },
      }
    }
  }

  if (override && override.channel) {
    context = {
      ...context,
      ...{ channel: override.channel },
    }
  }
  context.messages = (
    await import(`../../messages/${context.language || Languages.VN}.json`)
  ).default
  return context
}
export const DEFAULT_REGION = process?.env?.NEXT_PUBLIC_SALE_REGION_DEFAULT

export const getTranslate = (
  data?: Record<string, string> | Record<string, string[]> | any,
  lang?: Languages | null
) => {
  if (!data) return ''
  if (lang) {
    return data[lang]
  }
  const browserLang = getCookie('language')
  if (!isServer && browserLang) {
    if (data[browserLang as string]) {
      return data[browserLang as string]
    }
    return data[Languages.VN]
  }
  if (data[context.language]) {
    return data[context.language]
  }
  return data[Languages.VN]
}

export const isServer = typeof window === 'undefined'

function onSuccessfulRequest(config: AxiosConfig<any>): AxiosConfig<any> {
  const token = getCookie('token')
  const app = getCookie('app')
  const language = getCookie('language')
  const regions = getCookie('Area-Code')
  const guestId = getCookie('guestId')
  const channel = getCookie('channel')
  const destinationCode = getCookie('destinationCode')
  const accessToken = getCookie('access')
  const province = getCookie('province')
  const isNewVersion =
    getCookie(LocalStorageKeys.VERSION) !==
    process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION
  if (isNewVersion) {
    deleteCookie('province')
    deleteCookie('Area-Code')
    deleteCookie('channel')
    deleteCookie('province')
    deleteCookie('access')
    setCookie('access', process.env.NEXT_PUBLIC_ACCESS_TOKEN, {
      maxAge: 2147483647,
    })
    setCookie(
      'channel',
      app && app == 'b2e' ? SaleChannel.B2E : SaleChannel.B2C,
      {
        maxAge: 2147483647,
      }
    )
    setCookie('Area-Code', DEFAULT_REGION, {
      maxAge: 2147483647,
    })
    setCookie('language', Languages.VN, {
      maxAge: 2147483647,
    })
    setCookie(
      LocalStorageKeys.VERSION,
      process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION,
      {
        maxAge: 2147483647,
      }
    )
  }
  config.headers['province'] = province
  config.headers['Party'] = 'ecom'
  config.headers['Platform'] = Platform.MOBILE
  config.headers['Channel'] =
    app && app == 'b2e' ? SaleChannel.B2E : SaleChannel.B2C
  ;(config as AxiosMetadaInterface).metadata = { startTime: new Date() }
  // Client
  if (config.headers && !isServer) {
    config.headers['Accept-Language'] = language || Languages.VN
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (regions) config.headers['Area-Code'] = regions || DEFAULT_REGION
    if (guestId) config.headers['Uuid'] = guestId
    if (channel) config.headers['Channel'] = channel
    if (province) config.headers['province'] = province
    if (destinationCode) config.headers['destination-code'] = destinationCode
    if (accessToken) config.headers['Access'] = accessToken
    return config
  }

  // Server
  if (context) {
    const { token, language, channel, destinationCode } = context
    const areaCode = context['Area-Code']
    const province = context['province']
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (language) config.headers['Accept-language'] = language
    if (channel)
      config.headers['Channel'] =
        app && app == SaleChannel.B2E ? SaleChannel.B2E : channel
    if (destinationCode) config.headers['destination-code'] = destinationCode
    if (accessToken) config.headers['Access'] = accessToken
    if (province) config.headers['province'] = province
    config.headers['Area-Code'] = areaCode || DEFAULT_REGION
  }
  return config
}

const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'dev'
async function onFailedRequest(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}
function onSuccessfulResponse(res: AxiosResponse<any>): AxiosResponse<any> {
  ;(res.config as any).metadata.endTime = new Date()
  const duration =
    (res.config as any)?.metadata.endTime -
    (res.config as any)?.metadata.startTime
  if (!isProd) {
    console.log(
      `Response successfully received from ${res.config.url}`,
      duration
    )
  }
  return res
}
async function onFailedResponse(error: AxiosError): Promise<AxiosError> {
  ;(error.config as any).metadata.endTime = new Date()
  const duration =
    (error.config as any)?.metadata.endTime -
    (error.config as any)?.metadata.startTime
  if (!isProd) {
    console.log(
      `Error happened while creating the response ${error?.config?.url}`,
      duration
    )
  }
  return Promise.reject(error)
}

export const applyInterceptors = (instance: AxiosInstance): void => {
  instance.interceptors.request.use(onSuccessfulRequest, onFailedRequest)
  instance.interceptors.response.use(onSuccessfulResponse, onFailedResponse)
}
