/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageType, PageTypeSuffix } from '@/constants'
import type { QueryParam } from '@/types'
import { decode } from 'html-entities'
import isNil from 'lodash/isNil'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { getTranslate } from './api-interceptors'

export const convertQueryParam = (queryParam?: QueryParam): string => {
  if (!queryParam) {
    return ''
  }
  let str = ''
  Object.keys(queryParam).forEach((key: string) => {
    if (str !== '') {
      str += '&'
    }
    str += `${key}=${queryParam[key]}`
  })
  return str
}

export const getRndInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const usePrevious = (value: any) => {
  const prev = useRef()

  useEffect(() => {
    prev.current = value
  })

  return prev.current
}

export const useParams = (fallback: any) => {
  const router = useRouter()
  const { slug } = router.query
  const path = slug && slug[0]?.split('.')
  const hasQuery = Object.keys(router.query).length
  let currentParams = fallback

  // if query params present, update the current parameters
  if (hasQuery) {
    currentParams = fallback.map((param: any) => {
      if (!router.query[param.name]) {
        return param
      }
      const queryData = router.query[param.name] as string
      if (param.filterKeys) {
        const filterData = queryData.split('|').map((e: any) => {
          const data = e.split(':')
          return {
            key: data[0],
            value: data[1] || null,
          }
        })
        const filterKeys = param.filterKeys.map((e: any) => {
          return {
            ...e,
            value: filterData.find(d => d.key === e.name)?.value || null,
          }
        })
        return { ...param, value: filterData.map(e => e.value), filterKeys }
      }

      return { ...param, value: router.query[param.name] }
    })
  }

  // update the query params on change
  const setCurrentParams = useCallback(
    (params: any) => {
      currentParams = params

      const urlParams = params
        .filter((p: { value: any; name: any }) => {
          return (
            p.value !==
            fallback.find((fb: { name: any }) => fb.name === p.name)?.value
          )
        })
        .reduce((r: { [x: string]: any }, { name, value, filterKeys }: any) => {
          if (filterKeys) {
            value = filterKeys
              .filter((r: any) => !isNil(r.value))
              .map(
                (e: any) =>
                  `${e.name}:${
                    Array.isArray(e.value) ? e.value.split(',') : e.value
                  }`
              )
          }
          return (r[name] = value), r
        }, {})

      const qs = queryString.stringify(urlParams, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      })

      router.replace(
        `/${path && path[0] + PageTypeSuffix.CATEGORY}${
          qs ? `?${decodeURIComponent(qs)}` : ''
        }`,
        undefined,
        { scroll: false, shallow: true }
      )
    },
    [router]
  )

  return [currentParams, setCurrentParams]
}

export const useSearchParams = (fallback: any) => {
  const router = useRouter()
  const { slug } = router.query
  const path = slug ? slug[0]?.split('.') : router.pathname?.split('/')
  const hasQuery = Object.keys(router.query).length
  let currentParams = fallback
  // if query params present, update the current parameters
  if (hasQuery) {
    currentParams = fallback.map((param: any) => {
      if (!router.query[param.name]) {
        return param
      }
      const queryData = router.query[param.name] as string
      if (param.filterKeys) {
        const filterData = queryData.split('|').map((e: any) => {
          const data = e.split(':')
          return {
            key: data[0],
            value: data[1] || null,
          }
        })
        const filterKeys = param.filterKeys.map((e: any) => {
          return {
            ...e,
            value: filterData.find(d => d.key === e.name)?.value || null,
          }
        })
        return { ...param, value: filterData.map(e => e.value), filterKeys }
      }

      return { ...param, value: router.query[param.name] }
    })
  }

  // update the query params on change
  const setCurrentParams = useCallback(
    (params: any) => {
      currentParams = params

      const urlParams = params
        .filter((p: { value: any; name: any }) => {
          return (
            p.value !==
            fallback.find((fb: { name: any }) => fb.name === p.name)?.value
          )
        })
        .reduce((r: { [x: string]: any }, { name, value, filterKeys }: any) => {
          if (filterKeys) {
            value = filterKeys
              .filter((r: any) => !isNil(r.value))
              .map(
                (e: any) =>
                  `${e.name}:${
                    Array.isArray(e.value) ? e.value.split(',') : e.value
                  }`
              )
          }
          return (r[name] = value), r
        }, {})

      const qs = queryString.stringify(urlParams, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      })

      router.replace(
        `/${path && path[1]}${qs ? `?${decodeURIComponent(qs)}` : ''}`,
        undefined,
        { scroll: false, shallow: true }
      )
    },
    [router]
  )

  return [currentParams, setCurrentParams]
}

// keep number counters within a range
export const clampRange = (value: number, min = 0, max = 1) => {
  return value < min ? min : value > max ? max : value
}

export const cartesian = <T>(...arrays: Array<T>) => {
  return [...arrays].reduce(
    (a, b: any) =>
      a
        .map(x => b.map((y: any) => x.concat(y)))
        .reduce((a, b) => a.concat(b), []),
    [[]]
  )
}

export const delay = (time: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const getPath = (data: any): string => {
  const slug = getTranslate(data.value)
  if (slug && !data.postfix) {
    return slug
  }
  return `${slug}${data.postfix}`
}

export const copyToClipboard = (text: any) => {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  toast.info('Sao chép thành công', {
    position: 'bottom-center',
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  })
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const formatMoney = (money: number) => {
  const newMoney = Math.round(money)
  return new Intl.NumberFormat().format(newMoney)
}

export const getPageTypeFromPath = (path: string[]) => {
  if (path.length === 1) {
    return PageType.PRODUCT
  }
  if (path[1].indexOf('c') > -1) {
    return PageType.CATEGORY
  }
  if (path[1].indexOf('p') > -1) {
    return PageType.PAGE_CMS
  }
  if (path[1].indexOf('b') > -1) {
    return PageType.POST
  }
  return PageType.PRODUCT
}

export const validateInputLength = (text: string | undefined) => {
  if (text && text?.replace(/\s+/g, '').length > 0) {
    return true
  }
  return false
}

export function stripHtmlTagsAndLimit(text: string, maxLength: number) {
  if (!text) return ''
  let plainText = decode(text).replace(/<\/?[^>]+(>|$)/g, '')
  plainText = plainText.replace(/\s+/g, ' ').trim()
  if (plainText && plainText.length > maxLength) {
    plainText = plainText.substring(0, maxLength)
    const lastSpaceIndex = plainText.lastIndexOf(' ')
    plainText = plainText.substring(0, lastSpaceIndex)
    return plainText
  }

  return
}

export const convertCoupon = (couponCode: string, productId: string) => {
  return `${couponCode}_${productId}`
}
